






import Vue from 'vue';

export default Vue.extend({
  name: 'LoadingIndicator',
  props: {
    isLarge: { type: Boolean, default: false },
  },
});
